import React, { memo } from "react";
import { graphql } from "gatsby";
import PropTypes from "prop-types";

import Layout from "../components/layout";
import ImageModal from "../components/image-modal";
import Tiles from "../components/tiles";

const Field = ({ title, text }) => (
  <>
    <h3 className="title is-4">{title}</h3>
    <p className="subtitle is-6">{text}</p>
  </>
);

const JobTemplate = ({
  data: {
    job: { frontmatter }
  }
}) => {
  const tiles = frontmatter.images.map(({ thumbnail, full }) => (
    <ImageModal
      thumbnail={thumbnail}
      image={full}
      alt={thumbnail.fluid.originalName}
      key={thumbnail.fluid.originalName}
    />
  ));

  return (
    <Layout title={frontmatter.title}>
      <section className="section">
        <div className="container">
          <h1 className="title">{frontmatter.title}</h1>
          <h2 className="subtitle">
            {frontmatter.street}, {frontmatter.locality}
          </h2>
        </div>
      </section>

      <section className="section">
        <div className="container">
          <div className="columns">
            <div className="column is-8">
              <ImageModal
                image={frontmatter.mainImage.childImageSharp}
                alt={frontmatter.title}
              />
            </div>
            <div className="column">
              <Field title="Titolo" text={frontmatter.title} />
              <Field
                title="Indirizzo"
                text={`${frontmatter.street} ${frontmatter.locality} (${frontmatter.region}), ${frontmatter.postalCode}`}
              />
              <Field title="Data" text={frontmatter.date} />
              <Field title="Cliente" text={frontmatter.client} />
              <Field title="Attivita" text={frontmatter.activity} />
            </div>
          </div>
        </div>
      </section>

      <section className="section">
        <div className="container">
          <Tiles items={tiles} />
        </div>
      </section>
    </Layout>
  );
};

Field.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired
};

JobTemplate.propTypes = {
  data: PropTypes.shape({
    job: PropTypes.object
  }).isRequired
};

export const query = graphql`
  query($slug: String!) {
    job: markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        title
        client
        date
        locality
        region
        street
        postalCode
        activity
        mainImage {
          childImageSharp {
            fluid(quality: 85) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
        images {
          thumbnail: childImageSharp {
            fluid(maxWidth: 500, maxHeight: 300) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
              originalName
            }
          }
          full: childImageSharp {
            fluid(quality: 85, maxWidth: 1500) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    }
  }
`;

export default memo(JobTemplate);
