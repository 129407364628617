import React, { memo, useState } from "react";
import PropTypes from "prop-types";
import Img from "gatsby-image";

import styles from "../styles/image-modal.module.scss";

const ImageModal = ({ thumbnail, image, alt, className }) => {
  const [isActive, setIsActive] = useState(false);

  return (
    <div className={className}>
      <div
        role="presentation"
        className={styles.activator}
        onClick={() => setIsActive(true)}
      >
        <Img
          className={styles.image}
          fluid={thumbnail.fluid || image.fluid}
          alt={alt}
        />
      </div>

      <div className={`modal ${isActive ? "is-active" : ""}`}>
        <div
          role="presentation"
          className="modal-background"
          onClick={() => setIsActive(false)}
        />
        <div className="modal-content">
          <Img className={styles.image} fluid={image.fluid} alt={alt} />
        </div>
        <button
          type="button"
          className="modal-close is-large"
          aria-label="close"
          onClick={() => setIsActive(false)}
        />
      </div>
    </div>
  );
};

ImageModal.propTypes = {
  thumbnail: PropTypes.shape({ fluid: PropTypes.object }),
  image: PropTypes.shape({ fluid: PropTypes.object }).isRequired,
  alt: PropTypes.string.isRequired,
  className: PropTypes.string
};

ImageModal.defaultProps = {
  thumbnail: {},
  className: ""
};

export default memo(ImageModal);
